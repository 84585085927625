@import "../../../../../../DanskeSpil/Framework/Design/Styles/Mixins/DanskeSpilMixins.less"; 

.ds-buttons(@fontSize:1.5 ,@height: 4.0, @heightFF: 3.4, @width: 35%) {
  .button() {
    display: block;
    width: @width;
    min-width: 105px;
    margin: 0 auto;
    border-radius: 3px;
    .cm-font-ds-bold();
    .cm-font(@fontSize, @height, @heightFF);
    text-decoration: none;
    text-align: center;

    &:hover {
      text-decoration: none;
    }
  }
  .button-shadow() {
    box-shadow: @rgba-black-040 0 3px 2px;
  }

  .bingo-button {
    .button();
    background-color: rgb(128,169,28);
    border-top: 1px solid rgb(154,203,34);
    box-shadow: rgb(96,127,21) 0 0 0 1px;  
    .button-shadow();
    color: rgb(255,255,255);
    text-shadow: 0 1px 0 rgba(96,127,21,1.00);

    &:hover {
      color: rgb(255,255,255);
      background-color: rgb(149,195,35);
      border-top-color: rgb(158,208,35);
    }
  }

  .dantoto-button {
    .button();
    background-color: rgb(71,171,102);
    border-top: 1px solid rgb(88,194,120);
    box-shadow+: rgb(31,122,68) 0 0 0 1px; 
    .button-shadow();
    color: rgb(255,255,255);
    text-shadow: 0 1px 0 @rgba-black-040;

    &:hover {
      color: rgb(255,255,255);
      background-color: rgb(79,190,113);
      border-top-color: rgb(89,198,123);
    }
  }

  .danske-spil-button {
    .button();
    background-color: rgb(254,183,0);
    border-top: 1px solid rgb(255,219,128);
    box-shadow+: rgb(94,39,103) 0 0 0 1px; 
    .button-shadow();
    color: rgb(0,75,50);
    text-shadow: 0 -1px 0 rgba(255,255,255,0.50);

    &:hover {
      color: rgb(0,75,50);
      background-color: rgb(255,197,46);
      border-top-color: rgb(255,226,151);
    }
  }

  .live-casino-button {
    .button();
    background-color: #652044;
    border-top: 1px solid #9e71a5;
    box-shadow: #5f2768 0 0 0 1px;
    .button-shadow();
    color: #fff;
    text-shadow: 0 1px 0 #7c643b;

    &:hover {
      color: #fff;
      background-color: #973066;
      border-top-color: #a276a9;
    }
     

  }

  .quick-button {
    .button();
    background-color: rgb(127,68,136);
    border-top: 1px solid rgb(158,113,165);
    box-shadow: rgb(94,39,103) 0 0 0 1px;
    .button-shadow();
    color: #fff;
    text-shadow: 0 1px 0 rgb(127,68,136);

    &:hover {
      color: #fff;
      background-color: rgb(142,93,149);
      border-top-color: rgb(162,118,169);
    }
  }

  .ekstrachancen-button {
    .button();

    .cm-font-ds-black();
    .cm-font-size(1.4);
    color: rgb(38,54,30);
    text-shadow: 0 1px 1px rgba(255,255,255,.5);
    text-transform: uppercase;

    border-radius: 4px;
    box-shadow: 1px 1px 3px rgba(0,0,0,.25);
	
    background: #fdbe10; /* Old browsers */
    background: -moz-linear-gradient(top, #fdbe10 0%, #deb74a 50%, #dca50e 51%, #b4860b 100%); /* FF3.6+ */
    background: -webkit-linear-gradient(top, #fdbe10 0%,#deb74a 50%,#dca50e 51%,#b4860b 100%); /* Chrome10+,Safari5.1+ */
    background: -ms-linear-gradient(top, #fdbe10 0%,#deb74a 50%,#dca50e 51%,#b4860b 100%); /* IE10+ */
    background: linear-gradient(to bottom, #fdbe10 0%,#deb74a 50%,#dca50e 51%,#b4860b 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fdbe10', endColorstr='#b4860b',GradientType=0 ); /* IE6-9 */

    &:hover {
      color: rgb(38,54,30);
    }
  }
}