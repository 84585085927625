.container {
  margin-right: auto;
  margin-left: auto;
  padding-left: 12px;
  padding-right: 12px;
  /*
  @media (min-width: @screen-sm) {
    width: @container-sm;
  }*/
  /*
  @media (min-width: @screen-lg-min) {
    width: @container-lg;
  }*/
}
.container:before,
.container:after {
  content: " ";
  display: table;
}
.container:after {
  clear: both;
}
@media (min-width: 1000px) {
  .container {
    width: 1024px;
  }
}
.row {
  margin-left: -12px;
  margin-right: -12px;
}
.row:before,
.row:after {
  content: " ";
  display: table;
}
.row:after {
  clear: both;
}
.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12, .col-xs-13, .col-sm-13, .col-md-13, .col-lg-13, .col-xs-14, .col-sm-14, .col-md-14, .col-lg-14, .col-xs-15, .col-sm-15, .col-md-15, .col-lg-15, .col-xs-16, .col-sm-16, .col-md-16, .col-lg-16 {
  position: relative;
  min-height: 1px;
  padding-left: 12px;
  padding-right: 12px;
}
.col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs-13, .col-xs-14, .col-xs-15, .col-xs-16 {
  float: left;
}
.col-xs-16 {
  width: 100%;
}
.col-xs-15 {
  width: 93.75%;
}
.col-xs-14 {
  width: 87.5%;
}
.col-xs-13 {
  width: 81.25%;
}
.col-xs-12 {
  width: 75%;
}
.col-xs-11 {
  width: 68.75%;
}
.col-xs-10 {
  width: 62.5%;
}
.col-xs-9 {
  width: 56.25%;
}
.col-xs-8 {
  width: 50%;
}
.col-xs-7 {
  width: 43.75%;
}
.col-xs-6 {
  width: 37.5%;
}
.col-xs-5 {
  width: 31.25%;
}
.col-xs-4 {
  width: 25%;
}
.col-xs-3 {
  width: 18.75%;
}
.col-xs-2 {
  width: 12.5%;
}
.col-xs-1 {
  width: 6.25%;
}
.col-xs-pull-16 {
  right: 100%;
}
.col-xs-pull-15 {
  right: 93.75%;
}
.col-xs-pull-14 {
  right: 87.5%;
}
.col-xs-pull-13 {
  right: 81.25%;
}
.col-xs-pull-12 {
  right: 75%;
}
.col-xs-pull-11 {
  right: 68.75%;
}
.col-xs-pull-10 {
  right: 62.5%;
}
.col-xs-pull-9 {
  right: 56.25%;
}
.col-xs-pull-8 {
  right: 50%;
}
.col-xs-pull-7 {
  right: 43.75%;
}
.col-xs-pull-6 {
  right: 37.5%;
}
.col-xs-pull-5 {
  right: 31.25%;
}
.col-xs-pull-4 {
  right: 25%;
}
.col-xs-pull-3 {
  right: 18.75%;
}
.col-xs-pull-2 {
  right: 12.5%;
}
.col-xs-pull-1 {
  right: 6.25%;
}
.col-xs-pull-0 {
  right: 0%;
}
.col-xs-push-16 {
  left: 100%;
}
.col-xs-push-15 {
  left: 93.75%;
}
.col-xs-push-14 {
  left: 87.5%;
}
.col-xs-push-13 {
  left: 81.25%;
}
.col-xs-push-12 {
  left: 75%;
}
.col-xs-push-11 {
  left: 68.75%;
}
.col-xs-push-10 {
  left: 62.5%;
}
.col-xs-push-9 {
  left: 56.25%;
}
.col-xs-push-8 {
  left: 50%;
}
.col-xs-push-7 {
  left: 43.75%;
}
.col-xs-push-6 {
  left: 37.5%;
}
.col-xs-push-5 {
  left: 31.25%;
}
.col-xs-push-4 {
  left: 25%;
}
.col-xs-push-3 {
  left: 18.75%;
}
.col-xs-push-2 {
  left: 12.5%;
}
.col-xs-push-1 {
  left: 6.25%;
}
.col-xs-push-0 {
  left: 0%;
}
.col-xs-offset-16 {
  margin-left: 100%;
}
.col-xs-offset-15 {
  margin-left: 93.75%;
}
.col-xs-offset-14 {
  margin-left: 87.5%;
}
.col-xs-offset-13 {
  margin-left: 81.25%;
}
.col-xs-offset-12 {
  margin-left: 75%;
}
.col-xs-offset-11 {
  margin-left: 68.75%;
}
.col-xs-offset-10 {
  margin-left: 62.5%;
}
.col-xs-offset-9 {
  margin-left: 56.25%;
}
.col-xs-offset-8 {
  margin-left: 50%;
}
.col-xs-offset-7 {
  margin-left: 43.75%;
}
.col-xs-offset-6 {
  margin-left: 37.5%;
}
.col-xs-offset-5 {
  margin-left: 31.25%;
}
.col-xs-offset-4 {
  margin-left: 25%;
}
.col-xs-offset-3 {
  margin-left: 18.75%;
}
.col-xs-offset-2 {
  margin-left: 12.5%;
}
.col-xs-offset-1 {
  margin-left: 6.25%;
}
.col-xs-offset-0 {
  margin-left: 0%;
}
@media (min-width: 768px) {
  .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-13, .col-sm-14, .col-sm-15, .col-sm-16 {
    float: left;
  }
  .col-sm-16 {
    width: 100%;
  }
  .col-sm-15 {
    width: 93.75%;
  }
  .col-sm-14 {
    width: 87.5%;
  }
  .col-sm-13 {
    width: 81.25%;
  }
  .col-sm-12 {
    width: 75%;
  }
  .col-sm-11 {
    width: 68.75%;
  }
  .col-sm-10 {
    width: 62.5%;
  }
  .col-sm-9 {
    width: 56.25%;
  }
  .col-sm-8 {
    width: 50%;
  }
  .col-sm-7 {
    width: 43.75%;
  }
  .col-sm-6 {
    width: 37.5%;
  }
  .col-sm-5 {
    width: 31.25%;
  }
  .col-sm-4 {
    width: 25%;
  }
  .col-sm-3 {
    width: 18.75%;
  }
  .col-sm-2 {
    width: 12.5%;
  }
  .col-sm-1 {
    width: 6.25%;
  }
  .col-sm-pull-16 {
    right: 100%;
  }
  .col-sm-pull-15 {
    right: 93.75%;
  }
  .col-sm-pull-14 {
    right: 87.5%;
  }
  .col-sm-pull-13 {
    right: 81.25%;
  }
  .col-sm-pull-12 {
    right: 75%;
  }
  .col-sm-pull-11 {
    right: 68.75%;
  }
  .col-sm-pull-10 {
    right: 62.5%;
  }
  .col-sm-pull-9 {
    right: 56.25%;
  }
  .col-sm-pull-8 {
    right: 50%;
  }
  .col-sm-pull-7 {
    right: 43.75%;
  }
  .col-sm-pull-6 {
    right: 37.5%;
  }
  .col-sm-pull-5 {
    right: 31.25%;
  }
  .col-sm-pull-4 {
    right: 25%;
  }
  .col-sm-pull-3 {
    right: 18.75%;
  }
  .col-sm-pull-2 {
    right: 12.5%;
  }
  .col-sm-pull-1 {
    right: 6.25%;
  }
  .col-sm-pull-0 {
    right: 0%;
  }
  .col-sm-push-16 {
    left: 100%;
  }
  .col-sm-push-15 {
    left: 93.75%;
  }
  .col-sm-push-14 {
    left: 87.5%;
  }
  .col-sm-push-13 {
    left: 81.25%;
  }
  .col-sm-push-12 {
    left: 75%;
  }
  .col-sm-push-11 {
    left: 68.75%;
  }
  .col-sm-push-10 {
    left: 62.5%;
  }
  .col-sm-push-9 {
    left: 56.25%;
  }
  .col-sm-push-8 {
    left: 50%;
  }
  .col-sm-push-7 {
    left: 43.75%;
  }
  .col-sm-push-6 {
    left: 37.5%;
  }
  .col-sm-push-5 {
    left: 31.25%;
  }
  .col-sm-push-4 {
    left: 25%;
  }
  .col-sm-push-3 {
    left: 18.75%;
  }
  .col-sm-push-2 {
    left: 12.5%;
  }
  .col-sm-push-1 {
    left: 6.25%;
  }
  .col-sm-push-0 {
    left: 0%;
  }
  .col-sm-offset-16 {
    margin-left: 100%;
  }
  .col-sm-offset-15 {
    margin-left: 93.75%;
  }
  .col-sm-offset-14 {
    margin-left: 87.5%;
  }
  .col-sm-offset-13 {
    margin-left: 81.25%;
  }
  .col-sm-offset-12 {
    margin-left: 75%;
  }
  .col-sm-offset-11 {
    margin-left: 68.75%;
  }
  .col-sm-offset-10 {
    margin-left: 62.5%;
  }
  .col-sm-offset-9 {
    margin-left: 56.25%;
  }
  .col-sm-offset-8 {
    margin-left: 50%;
  }
  .col-sm-offset-7 {
    margin-left: 43.75%;
  }
  .col-sm-offset-6 {
    margin-left: 37.5%;
  }
  .col-sm-offset-5 {
    margin-left: 31.25%;
  }
  .col-sm-offset-4 {
    margin-left: 25%;
  }
  .col-sm-offset-3 {
    margin-left: 18.75%;
  }
  .col-sm-offset-2 {
    margin-left: 12.5%;
  }
  .col-sm-offset-1 {
    margin-left: 6.25%;
  }
  .col-sm-offset-0 {
    margin-left: 0%;
  }
}
@media (min-width: 1000px) {
  .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md-13, .col-md-14, .col-md-15, .col-md-16 {
    float: left;
  }
  .col-md-16 {
    width: 100%;
  }
  .col-md-15 {
    width: 93.75%;
  }
  .col-md-14 {
    width: 87.5%;
  }
  .col-md-13 {
    width: 81.25%;
  }
  .col-md-12 {
    width: 75%;
  }
  .col-md-11 {
    width: 68.75%;
  }
  .col-md-10 {
    width: 62.5%;
  }
  .col-md-9 {
    width: 56.25%;
  }
  .col-md-8 {
    width: 50%;
  }
  .col-md-7 {
    width: 43.75%;
  }
  .col-md-6 {
    width: 37.5%;
  }
  .col-md-5 {
    width: 31.25%;
  }
  .col-md-4 {
    width: 25%;
  }
  .col-md-3 {
    width: 18.75%;
  }
  .col-md-2 {
    width: 12.5%;
  }
  .col-md-1 {
    width: 6.25%;
  }
  .col-md-pull-16 {
    right: 100%;
  }
  .col-md-pull-15 {
    right: 93.75%;
  }
  .col-md-pull-14 {
    right: 87.5%;
  }
  .col-md-pull-13 {
    right: 81.25%;
  }
  .col-md-pull-12 {
    right: 75%;
  }
  .col-md-pull-11 {
    right: 68.75%;
  }
  .col-md-pull-10 {
    right: 62.5%;
  }
  .col-md-pull-9 {
    right: 56.25%;
  }
  .col-md-pull-8 {
    right: 50%;
  }
  .col-md-pull-7 {
    right: 43.75%;
  }
  .col-md-pull-6 {
    right: 37.5%;
  }
  .col-md-pull-5 {
    right: 31.25%;
  }
  .col-md-pull-4 {
    right: 25%;
  }
  .col-md-pull-3 {
    right: 18.75%;
  }
  .col-md-pull-2 {
    right: 12.5%;
  }
  .col-md-pull-1 {
    right: 6.25%;
  }
  .col-md-pull-0 {
    right: 0%;
  }
  .col-md-push-16 {
    left: 100%;
  }
  .col-md-push-15 {
    left: 93.75%;
  }
  .col-md-push-14 {
    left: 87.5%;
  }
  .col-md-push-13 {
    left: 81.25%;
  }
  .col-md-push-12 {
    left: 75%;
  }
  .col-md-push-11 {
    left: 68.75%;
  }
  .col-md-push-10 {
    left: 62.5%;
  }
  .col-md-push-9 {
    left: 56.25%;
  }
  .col-md-push-8 {
    left: 50%;
  }
  .col-md-push-7 {
    left: 43.75%;
  }
  .col-md-push-6 {
    left: 37.5%;
  }
  .col-md-push-5 {
    left: 31.25%;
  }
  .col-md-push-4 {
    left: 25%;
  }
  .col-md-push-3 {
    left: 18.75%;
  }
  .col-md-push-2 {
    left: 12.5%;
  }
  .col-md-push-1 {
    left: 6.25%;
  }
  .col-md-push-0 {
    left: 0%;
  }
  .col-md-offset-16 {
    margin-left: 100%;
  }
  .col-md-offset-15 {
    margin-left: 93.75%;
  }
  .col-md-offset-14 {
    margin-left: 87.5%;
  }
  .col-md-offset-13 {
    margin-left: 81.25%;
  }
  .col-md-offset-12 {
    margin-left: 75%;
  }
  .col-md-offset-11 {
    margin-left: 68.75%;
  }
  .col-md-offset-10 {
    margin-left: 62.5%;
  }
  .col-md-offset-9 {
    margin-left: 56.25%;
  }
  .col-md-offset-8 {
    margin-left: 50%;
  }
  .col-md-offset-7 {
    margin-left: 43.75%;
  }
  .col-md-offset-6 {
    margin-left: 37.5%;
  }
  .col-md-offset-5 {
    margin-left: 31.25%;
  }
  .col-md-offset-4 {
    margin-left: 25%;
  }
  .col-md-offset-3 {
    margin-left: 18.75%;
  }
  .col-md-offset-2 {
    margin-left: 12.5%;
  }
  .col-md-offset-1 {
    margin-left: 6.25%;
  }
  .col-md-offset-0 {
    margin-left: 0%;
  }
}
@media (min-width: 1200px) {
  .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-13, .col-lg-14, .col-lg-15, .col-lg-16 {
    float: left;
  }
  .col-lg-16 {
    width: 100%;
  }
  .col-lg-15 {
    width: 93.75%;
  }
  .col-lg-14 {
    width: 87.5%;
  }
  .col-lg-13 {
    width: 81.25%;
  }
  .col-lg-12 {
    width: 75%;
  }
  .col-lg-11 {
    width: 68.75%;
  }
  .col-lg-10 {
    width: 62.5%;
  }
  .col-lg-9 {
    width: 56.25%;
  }
  .col-lg-8 {
    width: 50%;
  }
  .col-lg-7 {
    width: 43.75%;
  }
  .col-lg-6 {
    width: 37.5%;
  }
  .col-lg-5 {
    width: 31.25%;
  }
  .col-lg-4 {
    width: 25%;
  }
  .col-lg-3 {
    width: 18.75%;
  }
  .col-lg-2 {
    width: 12.5%;
  }
  .col-lg-1 {
    width: 6.25%;
  }
  .col-lg-pull-16 {
    right: 100%;
  }
  .col-lg-pull-15 {
    right: 93.75%;
  }
  .col-lg-pull-14 {
    right: 87.5%;
  }
  .col-lg-pull-13 {
    right: 81.25%;
  }
  .col-lg-pull-12 {
    right: 75%;
  }
  .col-lg-pull-11 {
    right: 68.75%;
  }
  .col-lg-pull-10 {
    right: 62.5%;
  }
  .col-lg-pull-9 {
    right: 56.25%;
  }
  .col-lg-pull-8 {
    right: 50%;
  }
  .col-lg-pull-7 {
    right: 43.75%;
  }
  .col-lg-pull-6 {
    right: 37.5%;
  }
  .col-lg-pull-5 {
    right: 31.25%;
  }
  .col-lg-pull-4 {
    right: 25%;
  }
  .col-lg-pull-3 {
    right: 18.75%;
  }
  .col-lg-pull-2 {
    right: 12.5%;
  }
  .col-lg-pull-1 {
    right: 6.25%;
  }
  .col-lg-pull-0 {
    right: 0%;
  }
  .col-lg-push-16 {
    left: 100%;
  }
  .col-lg-push-15 {
    left: 93.75%;
  }
  .col-lg-push-14 {
    left: 87.5%;
  }
  .col-lg-push-13 {
    left: 81.25%;
  }
  .col-lg-push-12 {
    left: 75%;
  }
  .col-lg-push-11 {
    left: 68.75%;
  }
  .col-lg-push-10 {
    left: 62.5%;
  }
  .col-lg-push-9 {
    left: 56.25%;
  }
  .col-lg-push-8 {
    left: 50%;
  }
  .col-lg-push-7 {
    left: 43.75%;
  }
  .col-lg-push-6 {
    left: 37.5%;
  }
  .col-lg-push-5 {
    left: 31.25%;
  }
  .col-lg-push-4 {
    left: 25%;
  }
  .col-lg-push-3 {
    left: 18.75%;
  }
  .col-lg-push-2 {
    left: 12.5%;
  }
  .col-lg-push-1 {
    left: 6.25%;
  }
  .col-lg-push-0 {
    left: 0%;
  }
  .col-lg-offset-16 {
    margin-left: 100%;
  }
  .col-lg-offset-15 {
    margin-left: 93.75%;
  }
  .col-lg-offset-14 {
    margin-left: 87.5%;
  }
  .col-lg-offset-13 {
    margin-left: 81.25%;
  }
  .col-lg-offset-12 {
    margin-left: 75%;
  }
  .col-lg-offset-11 {
    margin-left: 68.75%;
  }
  .col-lg-offset-10 {
    margin-left: 62.5%;
  }
  .col-lg-offset-9 {
    margin-left: 56.25%;
  }
  .col-lg-offset-8 {
    margin-left: 50%;
  }
  .col-lg-offset-7 {
    margin-left: 43.75%;
  }
  .col-lg-offset-6 {
    margin-left: 37.5%;
  }
  .col-lg-offset-5 {
    margin-left: 31.25%;
  }
  .col-lg-offset-4 {
    margin-left: 25%;
  }
  .col-lg-offset-3 {
    margin-left: 18.75%;
  }
  .col-lg-offset-2 {
    margin-left: 12.5%;
  }
  .col-lg-offset-1 {
    margin-left: 6.25%;
  }
  .col-lg-offset-0 {
    margin-left: 0%;
  }
}
.container {
  min-width: 1024px;
}
.col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md-13, .col-md-14, .col-md-15, .col-md-16 {
  float: left;
}
.col-md-16 {
  width: 100%;
}
.col-md-15 {
  width: 93.75%;
}
.col-md-14 {
  width: 87.5%;
}
.col-md-13 {
  width: 81.25%;
}
.col-md-12 {
  width: 75%;
}
.col-md-11 {
  width: 68.75%;
}
.col-md-10 {
  width: 62.5%;
}
.col-md-9 {
  width: 56.25%;
}
.col-md-8 {
  width: 50%;
}
.col-md-7 {
  width: 43.75%;
}
.col-md-6 {
  width: 37.5%;
}
.col-md-5 {
  width: 31.25%;
}
.col-md-4 {
  width: 25%;
}
.col-md-3 {
  width: 18.75%;
}
.col-md-2 {
  width: 12.5%;
}
.col-md-1 {
  width: 6.25%;
}
.col-md-pull-16 {
  right: 100%;
}
.col-md-pull-15 {
  right: 93.75%;
}
.col-md-pull-14 {
  right: 87.5%;
}
.col-md-pull-13 {
  right: 81.25%;
}
.col-md-pull-12 {
  right: 75%;
}
.col-md-pull-11 {
  right: 68.75%;
}
.col-md-pull-10 {
  right: 62.5%;
}
.col-md-pull-9 {
  right: 56.25%;
}
.col-md-pull-8 {
  right: 50%;
}
.col-md-pull-7 {
  right: 43.75%;
}
.col-md-pull-6 {
  right: 37.5%;
}
.col-md-pull-5 {
  right: 31.25%;
}
.col-md-pull-4 {
  right: 25%;
}
.col-md-pull-3 {
  right: 18.75%;
}
.col-md-pull-2 {
  right: 12.5%;
}
.col-md-pull-1 {
  right: 6.25%;
}
.col-md-pull-0 {
  right: 0%;
}
.col-md-push-16 {
  left: 100%;
}
.col-md-push-15 {
  left: 93.75%;
}
.col-md-push-14 {
  left: 87.5%;
}
.col-md-push-13 {
  left: 81.25%;
}
.col-md-push-12 {
  left: 75%;
}
.col-md-push-11 {
  left: 68.75%;
}
.col-md-push-10 {
  left: 62.5%;
}
.col-md-push-9 {
  left: 56.25%;
}
.col-md-push-8 {
  left: 50%;
}
.col-md-push-7 {
  left: 43.75%;
}
.col-md-push-6 {
  left: 37.5%;
}
.col-md-push-5 {
  left: 31.25%;
}
.col-md-push-4 {
  left: 25%;
}
.col-md-push-3 {
  left: 18.75%;
}
.col-md-push-2 {
  left: 12.5%;
}
.col-md-push-1 {
  left: 6.25%;
}
.col-md-push-0 {
  left: 0%;
}
.col-md-offset-16 {
  margin-left: 100%;
}
.col-md-offset-15 {
  margin-left: 93.75%;
}
.col-md-offset-14 {
  margin-left: 87.5%;
}
.col-md-offset-13 {
  margin-left: 81.25%;
}
.col-md-offset-12 {
  margin-left: 75%;
}
.col-md-offset-11 {
  margin-left: 68.75%;
}
.col-md-offset-10 {
  margin-left: 62.5%;
}
.col-md-offset-9 {
  margin-left: 56.25%;
}
.col-md-offset-8 {
  margin-left: 50%;
}
.col-md-offset-7 {
  margin-left: 43.75%;
}
.col-md-offset-6 {
  margin-left: 37.5%;
}
.col-md-offset-5 {
  margin-left: 31.25%;
}
.col-md-offset-4 {
  margin-left: 25%;
}
.col-md-offset-3 {
  margin-left: 18.75%;
}
.col-md-offset-2 {
  margin-left: 12.5%;
}
.col-md-offset-1 {
  margin-left: 6.25%;
}
.col-md-offset-0 {
  margin-left: 0%;
}
