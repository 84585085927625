﻿@import (reference) "Mixins/_mixins.less";

.ds-buttons();
.oddset-button {
  &.large {
    width: 222px;
    .cm-font(2.0, 6.0, 5.3);
  }

  &.wide {
    width: 75%;
  }

  &.full { 
    width: 100%;  
  }
} 

.normal-button{ 
  .link-button(@height: 60px, @padding-top: 17px, @width: 220px, @font-size: 2.0); 
}

  
  
