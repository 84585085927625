﻿@padding-horizontal:        12px; //floor(@grid-gutter-width / 2);
@padding-bottom:            30px;

.col-default() {
  position: relative;
  float: left;
  min-height: 1px;
  padding-left: @padding-horizontal;
  padding-right: @padding-horizontal;
  padding-bottom: @padding-bottom;
}

.col-md-533 {
  position: relative;
  float: left;
  width: 33.3333%;
  min-height: 1px;
  padding-left: @padding-horizontal;
  padding-right: @padding-horizontal;
  padding-bottom: 30px;
}

.col-md-16 {
  .size-single {
    .col-default();
    width: 25%;
  }

  .size-double {
    .col-default();
    width: 50%;
  }

  .size-triple {
    .col-default();
    width: 75%;
  }

  .size-full {
    .col-default();
    width: 100%;
  }
}
.col-md-12 {
  .size-single {
    .col-default();
    width: 33.3333%;
  }

  .size-double {
    .col-default();
    width: 66.6666%;
  }

  .size-triple {
    .col-default();
    width: 100%;
  }

  .size-full {
    .col-default();
    width: 100%;
  }
}
.col-md-8 {
  .size-single {
    .col-default();
    width: 50%;
  }

  .size-double {
    .col-default();
    width: 100%;
  }

  .size-triple {
    .col-default();
    width: 100%;
  }

  .size-full {
    .col-default();
    width: 100%;
  }
}
.col-md-4 {
  .size-single {
    .col-default();
    width: 100%;
  }

  .size-double {
    .col-default();
    width: 100%;
  }

  .size-triple {
    .col-default();
    width: 100%;
  }

  .size-full {
    .col-default();
    width: 100%;
  }
}

.col-max-width {
  .col-default();
  width: 100%;
  padding-bottom: 0;
}

.col-1-4 {
  .col-default();
  width: 25%;
}

.col-3-4 {
  .col-default();
  width: 75%;
}

.col-1-3 {
  .col-default();
  width: 33%;
}
