﻿@import "../../../../../../../DanskeSpil/Framework/Design/Styles/Mixins/DanskeSpilMixins.less"; 

.link-button(@height: 40px, @padding-left-right: 23px, @padding-top: 9px, @width: auto, @font-size: 1.5) {
  display: inline-block;
  height: @height;
  width: @width;
  padding: @padding-top @padding-left-right 0;
  background-color: #0f3a77;
  border-top: 1px solid #879dbd;
  border-radius: 3px;
  box-shadow: rgba(0, 0, 0, 0.50) 0 0 0 1px, rgba(0, 0, 0, 0.50) 0 3px 2px;
  .cm-font-ds-bold();
  .cm-font-size(@font-size);
  color: #fff;
  text-decoration: none;
  text-align: center;
  text-shadow: 0 1px 0 #000;
  @media screen and (-moz-images-in-menus:0) { 
    padding-top: @padding-top - 2;
  }

  &:hover {
    background-color: #1a4e98;
    text-decoration: none;
  }
}