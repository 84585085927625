﻿@import (reference) "../variables.less";

body.region-oddset,
body.region-avalon-oddset,
body.region-kluboddset,
body[class*="color-oddset-"] {
  .seo-text-container,
  footer .info-bar {
    background: @oddset-background;
  }
}
