.global-footer-container {
  display: flex;
  flex-direction: row;
}
.global-footer .navigation-box .navigation-list {
  display: flex;
  flex-wrap: wrap;
}
.global-footer .navigation-box .navigation-item {
  flex: 1 0 auto;
  flex-basis: 50%;
}
.region-area {
  margin-top: 0;
}

@media (min-width: 960px) {
  .page-box > .outer-container {
    position: relative;
    z-index: 3;
  }
  .page-box > .page-area {
    position: relative;
    z-index: 2;
  }
  footer {
    z-index: 1;
  }
}

@media (min-width: 960px) {
  body.region-oddset footer {
    margin-top: 0px;
  }
}
@media only screen and (max-width: 1024px) {
  .global-footer-container {
    flex-direction: column;
  }

  .global-footer .address-box {
    order: 3;
  }

  .global-footer .navigation-box .navigation-list {
    order: 1;
  }

  .global-footer .contact-box {
    order: 3;
  }
}
.outer-container > .container {
  min-width: auto !important;
}

.header.top-navigation.sgd-ds-header-hide,
.header-static-fix.sgd-ds-header-hide {
  display: none;
}

@media only screen and (max-width: 768px) {
  header {
    transform: none !important;
  }
}

.digital-portal-wrapper {
  position: relative;
  z-index: 1;
}