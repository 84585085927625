.bingo-button {
  display: block;
  width: 35%;
  min-width: 105px;
  margin: 0 auto;
  border-radius: 3px;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-style: normal;
  text-decoration: none;
  text-align: center;
  background-color: #80a91c;
  border-top: 1px solid #9acb22;
  box-shadow: #607f15 0 0 0 1px;
  box-shadow: rgba(0, 0, 0, 0.4) 0 3px 2px;
  color: #ffffff;
  text-shadow: 0 1px 0 #607f15;
}
@media screen and (-moz-images-in-menus: 0) {
  .bingo-button {
    line-height: 3.4rem;
  }
}
.bingo-button:hover {
  text-decoration: none;
}
.bingo-button:hover {
  color: #ffffff;
  background-color: #95c323;
  border-top-color: #9ed023;
}
.dantoto-button {
  display: block;
  width: 35%;
  min-width: 105px;
  margin: 0 auto;
  border-radius: 3px;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-style: normal;
  text-decoration: none;
  text-align: center;
  background-color: #47ab66;
  border-top: 1px solid #58c278;
  box-shadow: #1f7a44 0 0 0 1px;
  box-shadow: rgba(0, 0, 0, 0.4) 0 3px 2px;
  color: #ffffff;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
}
@media screen and (-moz-images-in-menus: 0) {
  .dantoto-button {
    line-height: 3.4rem;
  }
}
.dantoto-button:hover {
  text-decoration: none;
}
.dantoto-button:hover {
  color: #ffffff;
  background-color: #4fbe71;
  border-top-color: #59c67b;
}
.danske-spil-button {
  display: block;
  width: 35%;
  min-width: 105px;
  margin: 0 auto;
  border-radius: 3px;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-style: normal;
  text-decoration: none;
  text-align: center;
  background-color: #feb700;
  border-top: 1px solid #ffdb80;
  box-shadow: #5e2767 0 0 0 1px;
  box-shadow: rgba(0, 0, 0, 0.4) 0 3px 2px;
  color: #004b32;
  text-shadow: 0 -1px 0 rgba(255, 255, 255, 0.5);
}
@media screen and (-moz-images-in-menus: 0) {
  .danske-spil-button {
    line-height: 3.4rem;
  }
}
.danske-spil-button:hover {
  text-decoration: none;
}
.danske-spil-button:hover {
  color: #004b32;
  background-color: #ffc52e;
  border-top-color: #ffe297;
}
.live-casino-button {
  display: block;
  width: 35%;
  min-width: 105px;
  margin: 0 auto;
  border-radius: 3px;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-style: normal;
  text-decoration: none;
  text-align: center;
  background-color: #652044;
  border-top: 1px solid #9e71a5;
  box-shadow: #5f2768 0 0 0 1px;
  box-shadow: rgba(0, 0, 0, 0.4) 0 3px 2px;
  color: #fff;
  text-shadow: 0 1px 0 #7c643b;
}
@media screen and (-moz-images-in-menus: 0) {
  .live-casino-button {
    line-height: 3.4rem;
  }
}
.live-casino-button:hover {
  text-decoration: none;
}
.live-casino-button:hover {
  color: #fff;
  background-color: #973066;
  border-top-color: #a276a9;
}
.quick-button {
  display: block;
  width: 35%;
  min-width: 105px;
  margin: 0 auto;
  border-radius: 3px;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-style: normal;
  text-decoration: none;
  text-align: center;
  background-color: #7f4488;
  border-top: 1px solid #9e71a5;
  box-shadow: #5e2767 0 0 0 1px;
  box-shadow: rgba(0, 0, 0, 0.4) 0 3px 2px;
  color: #fff;
  text-shadow: 0 1px 0 #7f4488;
}
@media screen and (-moz-images-in-menus: 0) {
  .quick-button {
    line-height: 3.4rem;
  }
}
.quick-button:hover {
  text-decoration: none;
}
.quick-button:hover {
  color: #fff;
  background-color: #8e5d95;
  border-top-color: #a276a9;
}
.ekstrachancen-button {
  display: block;
  width: 35%;
  min-width: 105px;
  margin: 0 auto;
  border-radius: 3px;
  font-weight: 700;
  text-decoration: none;
  text-align: center;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 800;
  font-style: normal;
  font-size: 1.4rem;
  color: #26361e;
  text-shadow: 0 1px 1px rgba(255, 255, 255, 0.5);
  text-transform: uppercase;
  border-radius: 4px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.25);
  background: #fdbe10;
  /* Old browsers */
  background: -moz-linear-gradient(top, #fdbe10 0%, #deb74a 50%, #dca50e 51%, #b4860b 100%);
  /* FF3.6+ */
  background: -webkit-linear-gradient(top, #fdbe10 0%, #deb74a 50%, #dca50e 51%, #b4860b 100%);
  /* Chrome10+,Safari5.1+ */
  background: -ms-linear-gradient(top, #fdbe10 0%, #deb74a 50%, #dca50e 51%, #b4860b 100%);
  /* IE10+ */
  background: linear-gradient(to bottom, #fdbe10 0%, #deb74a 50%, #dca50e 51%, #b4860b 100%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fdbe10', endColorstr='#b4860b', GradientType=0);
  /* IE6-9 */
}
@media screen and (-moz-images-in-menus: 0) {
  .ekstrachancen-button {
    line-height: 3.4rem;
  }
}
.ekstrachancen-button:hover {
  text-decoration: none;
}
.ekstrachancen-button:hover {
  color: #26361e;
}
.oddset-button.large {
  width: 222px;
}
@media screen and (-moz-images-in-menus: 0) {
  .oddset-button.large {
    line-height: 5.3rem;
  }
}
.oddset-button.wide {
  width: 75%;
}
.oddset-button.full {
  width: 100%;
}
.normal-button {
  display: inline-block;
  height: 60px;
  width: 220px;
  padding: 17px 23px 0;
  background-color: #0f3a77;
  border-top: 1px solid #879dbd;
  border-radius: 3px;
  box-shadow: rgba(0, 0, 0, 0.5) 0 0 0 1px, rgba(0, 0, 0, 0.5) 0 3px 2px;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 2rem;
  color: #fff;
  text-decoration: none;
  text-align: center;
  text-shadow: 0 1px 0 #000;
}
@media screen and (-moz-images-in-menus: 0) {
  .normal-button {
    padding-top: 15px;
  }
}
.normal-button:hover {
  background-color: #1a4e98;
  text-decoration: none;
}
