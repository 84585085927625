.region-oddset .subpage-wrapper {
  width: 100%;
}
.region-oddset .subpage-wrapper:before,
.region-oddset .subpage-wrapper:after {
  content: "";
  display: table;
}
.region-oddset .subpage-wrapper:after {
  clear: both;
}
.region-oddset .subpage-wrapper .primary-column {
  float: right;
  width: 74.4%;
}
.region-oddset .subpage-wrapper .aside-column {
  float: left;
  width: 23.2%;
}
.region-oddset .subpage-wrapper .headline {
  margin: 0;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 800;
  font-style: normal;
  font-size: 3rem;
  line-height: 3.5rem;
  color: #000;
}
.region-oddset .subpage-wrapper .sub-headline {
  margin: 0;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 2rem;
  line-height: 2.4rem;
  color: #000;
}
.region-oddset .subpage-wrapper .title {
  margin: 0;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 2rem;
  line-height: 2.4rem;
  color: #000;
}
.region-oddset .subpage-wrapper .text {
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 1.5rem;
  line-height: 1.8rem;
  color: #000;
}
.region-oddset .subpage-wrapper .text a {
  color: #001c47;
  text-decoration: none;
}
.region-oddset .subpage-wrapper .text a:hover {
  color: #b2c9eb;
}
.region-oddset .subpage-wrapper .text-link {
  color: #001c47;
  text-decoration: none;
}
.region-oddset .subpage-wrapper .text-link:hover {
  color: #b2c9eb;
}
.region-oddset .subpage-wrapper ol,
.region-oddset .subpage-wrapper ul {
  padding: 0;
}
.region-oddset .subpage-wrapper li {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
