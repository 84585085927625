﻿@import url("../variables.less");

.top-navigation-theme-oddset {

  @theme-color-a: @ds-color-oddset-corporate-nav;
  @theme-color-b: @ds-color-oddset-brand-nav;

  .menu-toggle-right {
    background-color: @theme-color-b;
  }

  .brand-nav {
    background-color: @theme-color-b;
  }

  .corporate-nav {
    background-color: @theme-color-a;
  }

  .mobile-navigation-header {
    background-color: @theme-color-b;
  }

  .mobile-navigation-footer-icon {
    background-color: @theme-color-b;
  }

  .mobile-navigation-item-link::before {
    background-color: @theme-color-b;
  }

  .mega-menu__campaign__cta-button {
    background-color: @theme-color-b;
    color: @ds-white;

    &:hover {
      background-color: @theme-color-a;
    }
  }

  @media all and (min-width: @nav-small) {
    .brand-nav__logo-img {
      position: relative;
      top: -4px;
    }
  }

  @media all and (max-width: (@nav-small - 1)) {
    .brand-nav__logo-img,
    .mobile-navigation-header__logo-img {
      position: relative;
      top: -3px;
    }
  }
}

body.region-oddset,
body.region-kluboddset {
  .page {
    &#wrapper {
      // Reposition the background-image below the new fixed header.
      &[style*="background-image"] {
        @media all and (min-width: @nav-medium) {
          background-position: center 143px; // Including 36 compliance header
        }
      }
    }
  }
}
