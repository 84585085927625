body.region-oddset .seo-text-container,
body.region-avalon-oddset .seo-text-container,
body.region-kluboddset .seo-text-container,
body[class*="color-oddset-"] .seo-text-container,
body.region-oddset footer .info-bar,
body.region-avalon-oddset footer .info-bar,
body.region-kluboddset footer .info-bar,
body[class*="color-oddset-"] footer .info-bar {
  background: #043c9b;
}
