﻿@import (reference) "Mixins/_mixins.less";

.region-oddset {

  .subpage-wrapper {
    .cm-clearfix();
    width: 100%;

    .primary-column {
      float: right;
      width: 74.4%;
    }

    .aside-column {
      float: left;
      width: 23.2%;
    }

    .headline {
      margin: 0;
      .cm-font-ds-black();
      .cm-font(3, 3.5);
      color: @text-color;
    }

    .sub-headline {
      margin: 0;
      .cm-font-ds-book();
      .cm-font(2, 2.4);
      color: @text-color;
    }

    .title {
      margin: 0;
      .cm-font-ds-book();
      .cm-font(2, 2.4);
      color: @text-color;
    }

    .text {
      .cm-font-ds-book();
      .cm-font(1.5, 1.8);
      color: @text-color;

      a {
        color: @link;
        text-decoration: none;

        &:hover {
          color: @link-hover;
        }
      }
    }

    .text-link {
      color: @link;
      text-decoration: none;

      &:hover {
        color: @link-hover;
      }
    }

    ol, ul {
      padding: 0;
    }

    li {
      margin: 0;
      padding: 0;
      list-style-type: none;
    }

  }
}
