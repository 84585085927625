.top-navigation-theme-oddset .menu-toggle-right {
  background-color: #1a4e98;
}
.top-navigation-theme-oddset .brand-nav {
  background-color: #1a4e98;
}
.top-navigation-theme-oddset .corporate-nav {
  background-color: #093d8c;
}
.top-navigation-theme-oddset .mobile-navigation-header {
  background-color: #1a4e98;
}
.top-navigation-theme-oddset .mobile-navigation-footer-icon {
  background-color: #1a4e98;
}
.top-navigation-theme-oddset .mobile-navigation-item-link::before {
  background-color: #1a4e98;
}
.top-navigation-theme-oddset .mega-menu__campaign__cta-button {
  background-color: #1a4e98;
  color: white;
}
.top-navigation-theme-oddset .mega-menu__campaign__cta-button:hover {
  background-color: #093d8c;
}
@media all and (min-width: 768px) {
  .top-navigation-theme-oddset .brand-nav__logo-img {
    position: relative;
    top: -4px;
  }
}
@media all and (max-width: 767px) {
  .top-navigation-theme-oddset .brand-nav__logo-img,
  .top-navigation-theme-oddset .mobile-navigation-header__logo-img {
    position: relative;
    top: -3px;
  }
}
@media all and (min-width: 950px) {
  body.region-oddset .page#wrapper[style*="background-image"],
  body.region-kluboddset .page#wrapper[style*="background-image"] {
    background-position: center 143px;
  }
}
