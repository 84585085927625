.col-md-533 {
  position: relative;
  float: left;
  width: 33.3333%;
  min-height: 1px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 30px;
}
.col-md-16 .size-single {
  position: relative;
  float: left;
  min-height: 1px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 30px;
  width: 25%;
}
.col-md-16 .size-double {
  position: relative;
  float: left;
  min-height: 1px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 30px;
  width: 50%;
}
.col-md-16 .size-triple {
  position: relative;
  float: left;
  min-height: 1px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 30px;
  width: 75%;
}
.col-md-16 .size-full {
  position: relative;
  float: left;
  min-height: 1px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 30px;
  width: 100%;
}
.col-md-12 .size-single {
  position: relative;
  float: left;
  min-height: 1px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 30px;
  width: 33.3333%;
}
.col-md-12 .size-double {
  position: relative;
  float: left;
  min-height: 1px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 30px;
  width: 66.6666%;
}
.col-md-12 .size-triple {
  position: relative;
  float: left;
  min-height: 1px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 30px;
  width: 100%;
}
.col-md-12 .size-full {
  position: relative;
  float: left;
  min-height: 1px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 30px;
  width: 100%;
}
.col-md-8 .size-single {
  position: relative;
  float: left;
  min-height: 1px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 30px;
  width: 50%;
}
.col-md-8 .size-double {
  position: relative;
  float: left;
  min-height: 1px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 30px;
  width: 100%;
}
.col-md-8 .size-triple {
  position: relative;
  float: left;
  min-height: 1px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 30px;
  width: 100%;
}
.col-md-8 .size-full {
  position: relative;
  float: left;
  min-height: 1px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 30px;
  width: 100%;
}
.col-md-4 .size-single {
  position: relative;
  float: left;
  min-height: 1px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 30px;
  width: 100%;
}
.col-md-4 .size-double {
  position: relative;
  float: left;
  min-height: 1px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 30px;
  width: 100%;
}
.col-md-4 .size-triple {
  position: relative;
  float: left;
  min-height: 1px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 30px;
  width: 100%;
}
.col-md-4 .size-full {
  position: relative;
  float: left;
  min-height: 1px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 30px;
  width: 100%;
}
.col-max-width {
  position: relative;
  float: left;
  min-height: 1px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 30px;
  width: 100%;
  padding-bottom: 0;
}
.col-1-4 {
  position: relative;
  float: left;
  min-height: 1px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 30px;
  width: 25%;
}
.col-3-4 {
  position: relative;
  float: left;
  min-height: 1px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 30px;
  width: 75%;
}
.col-1-3 {
  position: relative;
  float: left;
  min-height: 1px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 30px;
  width: 33%;
}
